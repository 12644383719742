<template>
  <div>
    <div
      class="perspective w-full h-full flex items-center justify-center my-5"
    >
      <div
        v-if="['participation', 'community', 'basic', 'role'].includes(type)"
        @click="flipCard"
        :class="[
          'card-container relative rounded-xl flex flex-col p-3 transform-style ',
          flipClass,
          flipShadowClass,
          formatSm ? 'w-[322px] h-[496px] p-5' : '',
          formatMd ? 'w-[233px] h-[356px] p-2' : 'w-[322px] h-[496px]',
          enableSelection
            ? selected
              ? 'border-secondary border-4'
              : 'border-white   border-4'
            : '',
          {
            'bg-[#231875] text-white': type === 'basic',
            'bg-[#8280fc] text-white': type === 'community',
            'bg-[#F3F3FF] text-black  ': [
              'membership',
              'participation',
            ].includes(type),
          },
        ]"
      >
        <div class="front-face relative">
          <div class="flex items-center mb-4">
            <img
              :src="profileImage || require('/assets/images/logoMydid.png')"
              alt=""
              class="w-7 h-7 rounded-full mr-1.5"
            />
            <p
              class="text-[20px] line-clamp-1"
              :class="
                ['membership', 'participation'].includes(type)
                  ? 'text-black  '
                  : 'text-white'
              "
            >
              {{ issuerDisplayedName }}
            </p>
            <p v-if="!formatSm" class="flex-none text-xxs ml-auto">
              <icon
                :name="
                  ['basic', 'community', 'membership', 'role'].includes(type)
                    ? `badge-${type}`
                    : 'badge-membership'
                "
                class="mr-0.5"
                :class="
                  ['membership', 'participation'].includes(type)
                    ? 'fill-black  '
                    : 'fill-white'
                "
              />
              <span
                class="text-xs"
                :class="
                  ['membership', 'participation'].includes(type)
                    ? 'text-black  '
                    : 'text-white'
                "
              >
                {{
                  type === 'basic'
                    ? 'Certified'
                    : type === 'community'
                    ? 'Peer-to-peer'
                    : type === 'membership'
                    ? 'Membership'
                    : type === 'role'
                    ? 'Role'
                    : 'Participation'
                }}
              </span>
            </p>
          </div>

          <div
            v-if="badgeImage"
            class="w-full aspect-square overflow-hidden mb-2"
          >
            <img
              :src="badgeImage"
              alt="badgePicture"
              class="object-cover rounded-lg overflow-hidden h-full w-full"
            />
          </div>
          <div
            v-else
            class="w-full border-gray-400 border rounded-xl aspect-square overflow-hidden"
          ></div>

          <div>
            <p
              :class="[
                'text-left font-medium leading-tight line-clamp-1 mb-1.5',
                formatSm ? 'text-xs' : 'text-md',
                ['membership', 'participation'].includes(type)
                  ? 'text-black  '
                  : 'text-white',
              ]"
            >
              {{ badgeName }}
            </p>
            <icon
              v-if="!isCommunityPage"
              :name="
                ['basic', 'community', 'membership', 'role'].includes(type)
                  ? `badge-${type}`
                  : 'badge-membership'
              "
              class="mr-0.5"
              :class="
                ['membership', 'participation'].includes(type)
                  ? 'fill-black  '
                  : 'fill-white'
              "
            />
            <div class="flex flex-row items-center">
              <p v-if="!isExpired" class="my-3">
                <icon name="Timer_Close" class="text-white text-xl mr-1.5" />
              </p>
              <p
                :class="[
                  'text-left font-medium leading-tight line-clamp-1 mb-1.5',
                  formatSm ? 'text-xs' : 'text-md',
                  ['membership', 'participation'].includes(type)
                    ? 'text-black  '
                    : 'text-white',
                ]"
              >
                {{ badgeLocation }}
              </p>
            </div>
            <p
              v-if="!isCommunityPage"
              class="text-xs text-left line-clamp-1 my-1.5"
            >
              Received : {{ new Date(badgeStartDate).toLocaleDateString() }}
            </p>
          </div>

          <div class="grow"></div>

          <div v-if="badgeSessions && type !== 'community'" class="flex">
            <div
              class="text-center bg-black rounded-md w-16 py-1"
              :class="{
                'bg-opacity-30': type === 'basic',
                'bg-opacity-15': type === 'community',
                'bg-opacity-[0.05]': ['membership', 'participation'].includes(
                  type
                ),
              }"
            >
              <p class="text-xs">
                <icon
                  name="ongoing"
                  class="mr-1.5 w-3"
                  :class="type === 'membership' ? 'fill-black  ' : 'fill-white'"
                />
                {{ ongoingSessions }}
              </p>
            </div>
            <div class="grow"></div>
            <div
              class="text-center bg-black rounded-md w-16 py-1"
              :class="{
                'bg-opacity-30': type === 'basic',
                'bg-opacity-15': type === 'community',
                'bg-opacity-[0.05]': type === 'membership',
              }"
            >
              <p class="text-xs">
                {{ deliveredBadges }}
              </p>
            </div>
          </div>

          <div
            :class="
              formatMd
                ? 'md:bottom-[-18%] bottom-[-16%] right-[-6%]'
                : 'md:bottom-[-12%] bottom-[-0%] right-[-4%]'
            "
            class="w-full h-full absolute flex items-end justify-end"
          >
            <img
              :src="
                ['membership', 'participation'].includes(type)
                  ? require('/assets/images/mydid_watermark_black.png')
                  : require('/assets/images/mydid_watermark_white.png')
              "
              alt=""
              :class="[
                'pb-2',
                type === 'Participation' ? 'opacity-[.07]' : 'opacity-15',
                formatMd ? 'w-[55px] lg:w-[60px]' : 'w-[50px] lg:w-[87px]',
              ]"
            />
          </div>
        </div>

        <div
          class="back-face absolute inset-0 rounded-xl overflow-hidden flex items-center justify-center"
          :class="{
            'bg-[#231875]': type === 'basic',
            'bg-[#8280fc]': type === 'community',
            'bg-[#F3F3FF]': ['membership', 'participation'].includes(type),
          }"
        >
          <img
            :src="
              ['membership', 'participation'].includes(type)
                ? require('/assets/images/logo_mydid.png')
                : require('/assets/images/logo_mydid2.png')
            "
            alt=""
            class="md:w-[250px] w-[200px] pb-2"
          />
        </div>
      </div>

      <div
        v-if="type === 'membership'"
        class="w-80 rounded-2xl relative flex items-center"
        :class="{
          'border-secondary border-4': enableSelection && selected,
          'border-transparent border-4': enableSelection && !selected,
          '!w-96 !rounded-2xl': formatXl,
        }"
      >
        <div
          v-if="badgeImage"
          class="w-full aspect-[85.60/53.98] rounded-xl flex justify-center items-center overflow-hidden"
        >
          <img
            :src="badgeImage"
            alt="badgePicture"
            class="object-cover min-h-full min-w-full"
          />
        </div>
        <div
          v-else
          class="w-full aspect-[85.60/53.98] bg-gray-light rounded-lg"
          :class="{
            '!w-96 !h-[245px]': formatXl,
            '!rounded-2xl': formatXl,
          }"
        ></div>
        <div
          class="absolute h-10 left-0 top-0 p-2"
          :class="{ '!h-12': formatXl }"
        >
          <div
            class="backdrop-blur-sm h-full inline-flex bg-[#050931] bg-opacity-40 rounded-md items-center px-2 py-1"
            :class="{ 'px-3 py-2': formatXl }"
          >
            <p
              class="text-white font-medium text-xs"
              :class="{ '!text-sm': formatXl }"
            >
              {{ membershipIssuerDisplayedName }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';

export default {
  props: {
    badgeImage: String,
    badgeName: String,
    badgeStartDate: String,
    badgeEndDate: String,
    badgeLocation: String,
    badgeSessions: Array,
    issuerName: String,
    issuerProfileImage: String,
    type: String,
    formatSm: Boolean,
    formatMd: Boolean,
    formatXl: Boolean,
    enableSelection: Boolean,
    selected: Boolean,
    profileImage: String,
    createdDate: String,
    isCommunityPage: Boolean,
  },
  setup(props) {
    const currentIssuerInfo = computed(() => {
      return {
        profile: {
          name: props.issuerName,
          profileImage: props.issuerProfileImage,
        },
      };
    });

    const ongoingSessions = ref(null);
    const deliveredBadges = ref(null);

    const issuerDisplayedName = computed(() => {
      return currentIssuerInfo.value.profile.name?.length >= 20
        ? currentIssuerInfo.value.profile.name.substring(0, 17) + '...'
        : currentIssuerInfo.value.profile.name;
    });

    if (props.badgeSessions) {
      ongoingSessions.value = props.badgeSessions.filter((session) => {
        if (session.didLimit !== 1) return false;
        return session.didHistory?.length === 0;
      })?.length;

      deliveredBadges.value = props.badgeSessions.reduce((sum, session) => {
        return sum + session.didHistory?.length;
      }, 0);
    }

    const isExpired = computed(() => {
      if (!props.badgeEndDate) return true;
      return new Date(props.badgeEndDate) < new Date();
    });

    const flipClass = ref('');
    const flipShadowClass = ref('');
    let flipTimer = null;
    const flipCard = () => {
      // Clear any existing timer
      if (flipTimer) {
        clearTimeout(flipTimer);
      }
      // Flip the card
      flipClass.value = flipClass.value === 'flipped' ? '' : 'flipped';
      flipShadowClass.value =
        flipShadowClass.value === 'flipped-shadow' ? '' : 'flipped-shadow';
      // Set a timer to flip the card back after 1 second
      flipTimer = setTimeout(() => {
        flipClass.value = '';
        flipShadowClass.value = '';
        flipTimer = null;
      }, 1500);
    };

    const membershipIssuerDisplayedName = computed(() => {
      return props.issuerName.length >= 20
        ? props.issuerName.substring(0, 17) + '...'
        : props.issuerName;
    });

    return {
      issuerDisplayedName,
      currentIssuerInfo,
      ongoingSessions,
      deliveredBadges,
      isExpired,
      flipClass,
      flipCard,
      flipShadowClass,
      membershipIssuerDisplayedName,
    };
  },
};
</script>

<style scoped>
.perspective {
  perspective: 1000px;
}

.transform-style {
  transform-style: preserve-3d;
  transition: transform 0.9s, box-shadow 0.9s;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
}

.flipped {
  transform: rotateY(360deg);
}

.flipped-shadow {
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.1);
}

.front-face,
.back-face {
  backface-visibility: hidden;
}

.back-face {
  transform: rotateY(180deg);
}

.card-container {
  --posx: 50%;
  --posy: 50%;
  --mx: 50%;
  --my: 50%;
}
</style>

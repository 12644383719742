import Axios from 'axios';
import { createToast } from 'mosha-vue-toastify';
import router from '../router';
import { verify } from 'jsonwebtoken';

const APIBaseURL = process.env.VUE_APP_BACKEND_URL;

const adapter = Axios.create({
  baseURL: APIBaseURL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: process.env.VUE_APP_BACKEND_API_KEY,
  },
});

const resolverAdapter = Axios.create({
  baseURL: process.env.VUE_APP_RESOLVER_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const communityMemberAdapter = Axios.create({
  baseURL: process.env.VUE_APP_COMMUNITY_MEMBER_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const publicViewCommunitiesadpater = Axios.create({
  baseURL: process.env.VUE_APP_PUBLIC_COMMUNITIES,
  headers: {
    'Content-Type': 'application/json',
  },
});

function eachRecursive(obj) {
  for (var k in obj) {
    if (typeof obj[k] == 'object' && obj[k] !== null) eachRecursive(obj[k]);
    else if (obj[k] == null) obj[k] = '';
  }
}

export default {
  install(app) {
    adapter.interceptors.response.use(
      (response) => {
        eachRecursive(response.data);
        return response;
      },
      (error) => {
        if (error.reponse) {
          if (
            error.response.status === 404 ||
            error.response.status === 401 ||
            error.response.status === 500 ||
            error.response.status === 503
          ) {
            router.push('/');
          }
        }

        if (error.message === 'Network Error') {
          console.log("Can't reach server");
          createToast("Can't reach server, please try again later", {
            position: 'bottom-center',
            hideProgressBar: true,
            toastBackgroundColor: '#111827',
          });
          router.push('/');
        }

        return Promise.reject(error);
      }
    );

    var api = {
      getPublicCommunities(start, limit, hidden) {
        return adapter.get(
          `/issuers?start=${start}&limit=${limit}&hidden=${hidden}`
        );
      },
      getPublicViewCommunities() {
        return adapter.get(`/issuers/public-community`);
      },
      login(password) {
        return adapter.post('/auth/login', { password });
      },
      verifyJwt(token) {
        return adapter.post('/auth/verify', { token });
      },
      hideCommunityAddress(address) {
        return adapter.post('/issuers/hide-community', { address });
      },
      getIssuerDetailsLinkFromCode(did) {
        return resolverAdapter.get(
          `/${did}?chainId=${process.env.VUE_APP_CHAIN_ID}`
        );
      },
      getVPDetailsFromCode(code) {
        return adapter.get('/vp/' + code);
      },
      getCommunityMemberCount(templateHash) {
        return communityMemberAdapter
          .get('/auth/sessions/membership-info/' + templateHash)
          .catch((error) => {
            console.log(error);
          });
      },
    };
    app.provide('api', api);
  },
};

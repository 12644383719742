<template>
  <div class="bg-white min-h-screen">
    <div v-if="!loading" class="w-full max-w-7xl mx-auto p-4">
      <div class="flex justify-between items-center mb-4">
        <div class="relative">
          <input
            v-model="searchQuery"
            type="text"
            placeholder="Find communities"
            class="pl-10 pr-4 py-2 border rounded-md w-64 focus:outline-none text-sm"
            @input="filterCommunities"
          />
          <span class="absolute left-3 top-2">
            <i class="fas fa-search text-gray-400"></i>
          </span>
        </div>
        <div class="flex flex-col lg:flex-row items-center space-x-2">
          <span class="text-sm text-gray-600">Order by</span>
          <select
            v-model="sortOrder"
            class="border rounded-md py-1 px-2 text-sm"
            @change="sortCommunities"
          >
            <option value="asc">Oldest first</option>
            <option value="desc">Newest first</option>
          </select>
        </div>
      </div>

      <div class="grid grid-cols-1 sm:grid-cols-2 my-10 md:grid-cols-3 gap-7">
        <div
          v-for="(community, index) in displayedCommunities"
          :key="index"
          class="relative border rounded-lg overflow-hidden shadow-sm"
        >
          <router-link :to="'/community/' + community.did">
            <img
              :src="community.templateData.image"
              :alt="community.templateData.name"
              class="w-full h-36 object-cover"
            />
            <div class="p-5">
              <div class="flex items-center justify-between">
                <div class="flex items-center space-x-2">
                  <img
                    :src="community.issuerProfile.profileImage"
                    alt="Avatar"
                    class="w-8 h-8 rounded-full"
                  />
                  <span class="font-medium text-[18px]">{{
                    community.issuerProfile.name
                  }}</span>
                </div>
                <span class="text-sm text-black text-[22px]">{{
                  community.createdDate.slice(-4)
                }}</span>
              </div>
              <div class="flex items-center justify-between mt-1">
                <span class="text-[18px] font-semibold ml-10">{{
                  community.issuerProfile.sector
                }}</span>
                <div class="flex items-center space-x-1">
                  <icon name="member-count" class="w-4 h-4"></icon>
                  <span class="text-sm text-black text-[15px]">{{
                    community.members
                  }}</span>
                </div>
              </div>
            </div>
            <p
              class="absolute top-[60%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-1 rounded-full text-sm"
            >
              <img
                :src="require('/assets/images/Certif.png')"
                alt="Avatar"
                class="w-6 h-6 rounded-full"
              />
            </p>
          </router-link>
        </div>
      </div>
      <div class="flex justify-center mt-6">
        <button
          v-if="displayedCommunities.length < filteredCommunities.length"
          @click="loadMore"
          class="bg-indigo-600 text-white px-4 py-2 rounded-md"
        >
          Load more
        </button>
      </div>
    </div>
    <div v-else class="flex justify-center items-center gap-3 mt-10">
      <custom-loader class="" color="#D0C4E7" size="50px"></custom-loader>
      <p class="text-lg text-gray-400">Loading...</p>
    </div>
  </div>
</template>

<script>
import { ref, inject } from 'vue';

export default {
  setup() {
    const api = inject('api');
    const utils = inject('utils');
    const allCommunities = ref([]);
    const filteredCommunities = ref([]);
    const displayedCommunities = ref([]);
    const loading = ref(true);
    const searchQuery = ref('');
    const sortOrder = ref('desc');
    const itemsPerPage = 6;

    async function fetchIssuerDetails(did) {
      let issuerProfile;
      let memberCount;
      let oldestTemplateData;
      let templateData;

      await Promise.all([
        new Promise(async (resolve, reject) => {
          let profileIpfsUrl;
          try {
            const { data } = await api.getIssuerDetailsLinkFromCode(did);
            profileIpfsUrl = data.service[0]?.serviceEndpoint;
          } catch (e) {
            reject(`Error fetching did document for DID ${did}:`, e);
          }

          try {
            issuerProfile = await utils.getJsonDataFromUrl(profileIpfsUrl);
          } catch (e) {
            reject(`Error fetching profile for DID ${did}:`, e);
          }

          resolve();
        }),
        new Promise(async (resolve, reject) => {
          let templateList;
          const addr = utils.didToAddress(did);
          try {
            templateList = await utils.getTemplateList(addr, 'Membership');
          } catch (e) {
            reject(`Error retrieving templates for DID ${did}:`, e);
          }

          const membercardTemplateHash = templateList[0].templateHash;
          try {
            [templateData, memberCount, oldestTemplateData] = await Promise.all(
              [
                utils.getTemplateData(membercardTemplateHash),
                api.getCommunityMemberCount(
                  membercardTemplateHash.split('0x')[1]
                ),
                utils
                  .getOldestTemplateHash(membercardTemplateHash)
                  .then(utils.getTemplateData),
              ]
            );
          } catch (e) {
            reject(`Error retrieving membercard data for ${did}:`, e);
          }

          resolve();
        }),
      ]).catch((e) => {
        console.error('Error fetching issuer details:', e);
        throw e;
      });

      return {
        did,
        issuerProfile,
        members: memberCount.data.members.number,
        createdDate: new Date(oldestTemplateData.created).toLocaleDateString(),
        templateData,
      };
    }

    async function fetchAllIssuersDetails() {
      loading.value = true;
      try {
        const res = await api.getPublicViewCommunities();
        const issuersDids = res.data.public;
        allCommunities.value = await Promise.all(
          issuersDids.map(fetchIssuerDetails)
        );
        filteredCommunities.value = [...allCommunities.value];
        sortCommunities();
        displayedCommunities.value = filteredCommunities.value.slice(
          0,
          itemsPerPage
        );
      } catch (error) {
        console.error('Error fetching issuers details:', error);
      }
      loading.value = false;
    }

    function sortCommunities() {
      filteredCommunities.value.sort((a, b) => {
        const yearA = parseInt(a.createdDate.slice(-4));
        const yearB = parseInt(b.createdDate.slice(-4));

        if (yearA === yearB) {
          // if years are the same, we sort by date
          const dateA = new Date(a.createdDate);
          const dateB = new Date(b.createdDate);
          return sortOrder.value === 'asc' ? dateA - dateB : dateB - dateA;
        }

        // else we sort by year
        return sortOrder.value === 'asc' ? yearA - yearB : yearB - yearA;
      });

      displayedCommunities.value = filteredCommunities.value.slice(
        0,
        itemsPerPage
      );
    }

    function filterCommunities() {
      filteredCommunities.value = allCommunities.value.filter(
        (community) =>
          community.issuerProfile.name
            .toLowerCase()
            .includes(searchQuery.value.toLowerCase()) ||
          community.issuerProfile.sector
            .toLowerCase()
            .includes(searchQuery.value.toLowerCase())
      );
      sortCommunities();
    }

    function loadMore() {
      const currentLength = displayedCommunities.value.length;
      const newItems = filteredCommunities.value.slice(
        currentLength,
        currentLength + itemsPerPage
      );
      displayedCommunities.value = [...displayedCommunities.value, ...newItems];
    }

    fetchAllIssuersDetails();

    return {
      displayedCommunities,
      filteredCommunities,
      loading,
      searchQuery,
      sortOrder,
      filterCommunities,
      sortCommunities,
      loadMore,
    };
  },
};
</script>

<template>
  <div class="">
    <div
      class="flex flex-col gap-6 sm:gap-8 lg:flex-row lg:gap-10 p-2 sm:p-4 lg:p-5 2xl:p-10 w-full"
    >
      <div
        v-if="
          verifiableCredential.type !== 'original' &&
          verifiableCredential.type !== 'role'
        "
        class="flex-none"
      >
        <TemplatePreview
          :badgeImage="verifiableCredential.image"
          :badgeName="verifiableCredential.name"
          :badgeLocation="
            verifiableCredential.type === 'participation'
              ? verifiableCredential.location.split(', ')[0] || 'Virtual'
              : null
          "
          :badgeStartDate="
            verifiableCredential.type === 'participation'
              ? verifiableCredential.startDate
              : null
          "
          :badgeEndDate="
            verifiableCredential.type === 'participation'
              ? verifiableCredential.endDate
              : null
          "
          :issuerName="verifiableCredential.issuer.name"
          :formatSm="true"
          :type="verifiableCredential.type"
          class="lg:hidden"
          :profileImage="verifiableCredential.issuer.profileImage"
        />
        <TemplatePreview
          :badgeImage="verifiableCredential.image"
          :badgeName="verifiableCredential.name"
          :badgeLocation="
            verifiableCredential.type === 'participation'
              ? verifiableCredential.location.split(', ')[0] || 'Virtual'
              : null
          "
          :badgeStartDate="
            verifiableCredential.type === 'participation'
              ? verifiableCredential.created
              : verifiableCredential?.created
          "
          :badgeEndDate="verifiableCredential.endDate"
          :issuerName="verifiableCredential.issuer.name"
          :formatXl="verifiableCredential.type !== 'membership'"
          :type="verifiableCredential.type"
          class="hidden lg:flex lg:flex-none"
          :profileImage="verifiableCredential.issuer.profileImage"
        />
      </div>

      <div class="grow">
        <!-- Certified and community badge membership -->
        <div
          v-if="
            verifiableCredential.type === 'basic' ||
            verifiableCredential.type === 'community' ||
            verifiableCredential.type === 'membership' ||
            verifiableCredential.type === 'role' ||
            verifiableCredential.type === 'participation'
          "
        >
          <div class="flex flex-row mb-5">
            <div class="flex items-center space-x-2 text-gray-400">
              <div class="tooltip">
                <icon name="help_1" class="fill-black w-[20px]" />
                <span class="tooltip-text"> Type of the badge </span>
              </div>
              <p class="text-[14px] md:text-[15px] w-[130px]">Badge Type:</p>
            </div>
            <div
              class="flex-row flex justify-center items-center text-start text-[15px] font-medium"
            >
              <p class="text-start text-[14px] md:text-[15px] font-medium">
                {{
                  verifiableCredential.type === 'community'
                    ? 'Peer-to-peer'
                    : null
                }}
                {{
                  verifiableCredential.type === 'membership'
                    ? 'Membership'
                    : null
                }}
                {{ verifiableCredential.type === 'basic' ? 'Certified' : null }}
                {{ verifiableCredential.type === 'role' ? 'Role' : null }}
                {{
                  verifiableCredential.type === 'participation'
                    ? 'Participation'
                    : null
                }}
              </p>
              <div
                v-if="
                  verifiableCredential.type === 'community' &&
                  verifiableCredential.endorsement
                "
                class="bg-green-100 border-green-300 text-green-500 border m-1 flex flex-row justify-center items-center py-[2px px-1 rounded-lg"
              >
                <icon name="star" class="fill-green-500 w-[12px]" />
                <p class="text-[10px] md:text-[10px]">Approved</p>
              </div>
            </div>
          </div>

          <div class="flex flex-row mb-5">
            <div class="flex items-center space-x-2 text-gray-400">
              <div class="tooltip">
                <icon name="help_1" class="fill-black w-[20px]" />
                <span class="tooltip-text"
                  >Name of the badge that is displayed on the badge</span
                >
              </div>
              <p class="text-[14px] md:text-[15px] w-[130px]">Badge Name:</p>
            </div>
            <div class="flex-col text-start text-[15px] font-medium">
              <p class="text-[14px] md:text-[15px] font-medium">
                {{ verifiableCredential.name }}
              </p>
            </div>
          </div>

          <div class="flex flex-row mb-5">
            <div class="flex items-center space-x-2 text-gray-400">
              <div class="tooltip">
                <icon name="help_1" class="fill-black w-[20px]" />
                <span class="tooltip-text"
                  >Link to the badge that can be shared with others</span
                >
              </div>
              <p class="text-[14px] md:text-[15px] w-[130px]">Badge Link:</p>
            </div>
            <div
              class="flex-row flex space-x-2 text-start text-[15px] font-medium"
            >
              <p
                class="text-[12px] md:text-[15px] font-medium italic hidden md:block overflow-hidden"
              >
                {{ code.slice(0, 11) + '...' + code.slice(50) }}
              </p>
              <button @click.stop="copyCode" class="cursor-pointer">
                <icon name="copy_1" class="fill-black w-[20px]" />
              </button>
            </div>
          </div>

          <div class="flex flex-row mb-5">
            <div class="flex items-center space-x-2 text-gray-400">
              <div class="tooltip">
                <icon name="help_1" class="fill-black w-[20px]" />
                <span class="tooltip-text"
                  >Status of the badge, whether it is valid or invalid</span
                >
              </div>
              <p class="text-[14px] md:text-[15px] w-[130px]">Status:</p>
            </div>
            <div
              class="flex-col text-start text-[12px] md:text-[15px] font-medium"
            >
              <div
                v-if="
                  verifiableCredential.expirationDate &&
                  new Date(verifiableCredential.expirationDate) < new Date()
                "
                class="ml-auto"
              >
                <p
                  class="bg-red-100 border-red-300 text-red-500 border py-[1px] px-1 rounded-lg"
                >
                  <icon
                    name="triangle-exclamation"
                    class="fill-red-500 w-[12px]"
                  />
                  <span class="text-[11px] md:text-[11px]">invalid</span>
                </p>
              </div>
              <div v-else class="  ">
                <div
                  class="bg-green-100 border-green-300 text-green-500 border py-[2px] px-1 flex flex-row justify-center items-center rounded-lg"
                >
                  <icon name="wavy-check" class="fill-green-500 w-[14px]" />
                  <span class="text-[11px] md:text-[11px]">Valid</span>
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-row mb-5">
            <div class="flex items-center space-x-2 text-gray-400">
              <div class="tooltip">
                <icon name="help_1" class="fill-black w-[20px]" />
                <span class="tooltip-text">Time when the badge was issued</span>
              </div>
              <p class="text-[14px] md:text-[15px] w-[130px]">Time:</p>
            </div>
            <div
              class="text-start text-[15px] flex flex-col md:flex-row gap-2 font-medium"
            >
              <p class="text-[14px] md:text-[15px] font-medium">
                {{ formatDate(verifiableCredential.proof_date) }}
              </p>

              <div
                v-if="
                  Math.abs(
                    new Date(vc.timestamp * 1000) -
                      new Date(verifiableCredential.created)
                  ) /
                    1000 <
                  120
                "
                class=""
              >
                <div
                  class="bg-green-100 border-green-300 text-green-500 border w-1/2 lg:w-full py-[2px] px-[2px] flex flex-row justify-center items-center rounded-lg"
                >
                  <icon name="clock" class="fill-green-500 w-[14px]" />
                  <span class="text-[11px] md:text-[11px]">Time-stamped</span>
                </div>
              </div>
            </div>
          </div>

          <!--  If Community and role -->
          <div class="flex flex-row py-5 border-b-[0.2px] border-t-[0.2px]">
            <div
              class="flex items-center lg:space-x-2 space-x-0 w-40 text-gray-400"
            >
              <icon name="help_1" class="fill-black w-[20px]" />
              <p v-if="verifiableCredential.type !== 'community'" class="">
                Community:
              </p>
              <p v-else class="">Sender:</p>
            </div>
            <a
              :href="
                verifiableCredential.type === 'community' &&
                communityTemplateData?.issuerDID
                  ? '/community/' + communityTemplateData.issuerDID
                  : !communityTemplateData?.issuerDID &&
                    verifiableCredential.type === 'community'
                  ? '#'
                  : '/community/' + verifiableCredential.issuer.did
              "
              class="flex items-center"
            >
              <img
                :src="
                  verifiableCredential.issuer.type === 'Issuer Profile'
                    ? verifiableCredential.issuer.profileImage.replace(
                        'https://ipfs.infura.io/ipfs/',
                        'https://myntfsid.mypinata.cloud/ipfs/'
                      )
                    : verifiableCredential.endorsement
                    ? verifiableCredential.endorsement.image
                    : require('/assets/images/logoMydid.png')
                "
                alt=""
                class="rounded-full border w-12 h-12 mr-5"
              />
              <p class="text-lg font-medium">
                {{ verifiableCredential.issuer.name }}
              </p>
            </a>
          </div>

          <div
            v-if="
              verifiableCredential.type === 'community' &&
              verifiableCredential.endorsement
            "
            class="flex flex-row py-5 border-b-[0.2px]"
          >
            <div
              class="flex items-center lg:space-x-2 space-x-0 w-40 text-gray-400"
            >
              <icon name="help_1" class="fill-black w-[20px]" />
              <p class="px-4">Role:</p>
            </div>
            <div>
              <TemplateRolePreview
                v-if="verifiableCredential.endorsement.type === 'role'"
                :name="verifiableCredential.endorsement.name"
                :emoji="verifiableCredential.endorsement.image"
                :color="verifiableCredential.endorsement.color"
                class="z-50"
                :profileImage="verifiableCredential.issuer?.profileImage"
                :type="verifiableCredential.endorsement.type"
              ></TemplateRolePreview>
              <img
                v-else
                :src="
                  require(`/assets/images/vc_${verifiableCredential.key}.png`)
                "
                class="w-20 max-w-none z-50"
                alt=""
              />
            </div>
          </div>

          <div class="flex flex-col py-2 border-b-[0.2px] mb-5">
            <div
              v-if="verifiableCredential.type === 'participation'"
              class="flex flex-col py-2 border-b-[0.2px] mb-5"
            >
              <div class="flex items-center space-x-2 text-black font-medium">
                <icon name="event_date" class="fill-black w-[20px]" />
                <p class="">Event date:</p>
              </div>
              <div class="flex items-center">
                <p class="text-[15px] px-6 font-medium">
                  {{
                    new Date(
                      verifiableCredential.startDate
                    ).toLocaleDateString()
                  }}
                </p>
              </div>
            </div>

            <div
              v-if="verifiableCredential.type === 'participation'"
              class="flex flex-col py-2 border-b-[0.2px] mb-5"
            >
              <div
                class="flex items-center space-x-2 w-40 text-black font-medium"
              >
                <icon name="location_1" class="fill-black w-[20px]" />
                <p class="">Location:</p>
              </div>
              <div class="flex items-center">
                <p class="text-[15px] px-6 font-medium">
                  {{ verifiableCredential.location }}
                </p>
              </div>
            </div>
            <div
              v-if="verifiableCredential.description"
              class="flex items-center space-x-2 text-black font-medium"
            >
              <icon name="description" class="fill-black w-[20px]" />
              <p class="">Description:</p>
            </div>
            <div class="flex items-center">
              <p class="text-[17px] px-6">
                {{ verifiableCredential.description }}
              </p>
            </div>
          </div>
          <div
            v-if="verifiableCredential.criteria"
            class="flex flex-col py-2 mb-5"
          >
            <div class="flex items-center space-x-2 text-black font-medium">
              <icon name="criteria" class="fill-black w-[20px]" />
              <p class="">Criteria:</p>
            </div>
            <div class="flex px-6 items-center text-gray-500">
              <p class="text-[15px]">
                {{ verifiableCredential.criteria }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject } from 'vue';
import TemplatePreview from './TemplatePreview.vue';
import TemplateRolePreview from './TemplateRolePreview.vue';
import { useRoute } from 'vue-router';
import { createToast } from 'mosha-vue-toastify';

export default {
  props: {
    vc: Object,
  },
  setup(props) {
    const verifiableCredential = ref(props.vc);
    const dynamicQrCodeSrc = ref(null);
    const utils = inject('utils');
    const communityTemplateData = ref(null);
    const templateLabel = ref(
      templateTypeToLabel(verifiableCredential.value.type)
    );

    const route = useRoute();
    const code = ref(route.query.code);
    const isCopied = ref(false);

    console.log('verifiableCredential', verifiableCredential);

    if (verifiableCredential.value.type === 'community') {
      utils
        .getTemplateData(verifiableCredential.value.templateHash)
        .then((communityTemplateDataFromIpfs) => {
          utils
            .isIssuer(
              utils.didToAddress(communityTemplateDataFromIpfs.issuerDID)
            )
            .then((isIssuer) => {
              if (isIssuer) {
                communityTemplateData.value = communityTemplateDataFromIpfs;
              }
            });
          utils
            .getIssuerCategory(
              utils.didToAddress(communityTemplateDataFromIpfs.issuerDID)
            )
            .then((category) => {
              communityTemplateData.value.category = category;
            });
        });
    }

    function templateTypeToLabel(type) {
      switch (type) {
        case 'basic':
          return 'Certified badge';
        case 'community':
          return 'Community badge';
        case 'participation':
          return 'Participation badge';
        case 'membership':
          return 'Membership card';
        case 'role':
          return 'Role badge';
      }
    }

    function formatDate(dateString) {
      return utils.formatDateWithRelativeTime(dateString);
    }

    function copyCode() {
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(code.value).then(
          () => {
            createToast('Code copied to clipboard', {
              type: 'success',
              showIcon: true,
              position: 'top-right',
              toastBackgroundColor: '#050931',
            });
            isCopied.value = true;
            setTimeout(() => {
              isCopied.value = false;
            }, 500);
          },
          (err) => {
            console.error('Could not copy text: ', err);
          }
        );
      }
    }

    return {
      verifiableCredential,
      dynamicQrCodeSrc,
      templateLabel,
      templateTypeToLabel,
      formatDate,
      code,
      copyCode,
      isCopied,
      communityTemplateData,
    };
  },

  components: {
    TemplatePreview,
    TemplateRolePreview,
  },
};
</script>

<style scoped>
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltip-text::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #23285c transparent transparent;
}
.tooltip .tooltip-text {
  visibility: hidden;
  background-color: #23285c;
  color: #fff;
  text-align: left; /* Alignement à gauche pour le texte */
  border-radius: 4px; /* Bordures arrondies */
  padding: 5px 10px; /* Ajout de padding pour le texte */
  width: 200px; /* Largeur maximale de 200px */
  max-height: 200px; /* Largeur maximale de 200px */
  white-space: pre-wrap; /* Permettre les retours à la ligne */
  position: absolute;
  z-index: 1;
  left: 120%;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.dark .tooltip .tooltip-text {
  background-color: #23285c;
  color: #fff;
}

.dark .tooltip .tooltip-text::after {
  border-color: transparent #23285c transparent transparent;
}
</style>

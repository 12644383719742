<template>
  <div class="min-h-screen">
    <div
      class="flex flex-col lg:flex-row justify-center items-center py-16 lg:p-[120px] mx-auto"
    >
      <div class="flex flex-col max-w-5xl p-8">
        <h1
          class="text-4xl font-semibold text-gray-800 mb-6 lg:text-[40px] max-w-3xl"
        >
          Verification and Exploration of Badges and Communities
        </h1>
        <p class="text-lg text-gray-800 mb-6 lg:text-[20px] lg:mb-9">
          On MyDid Check, not only can you verify the proof of possession of
          your digital badges, but you can also explore the communities that
          issued them.
        </p>
        <p class="text-lg text-gray-800 mb-6 lg:text-[20px] lg:mb-10">
          View the badges offered, and join the communities to enrich your
          skills and network.
        </p>
        <div class="flex space-x-4">
          <router-link
            to="/communities"
            class="bg-[#4030C6] hover:bg-[#4030C6] text-white font-bold py-2 px-4 rounded"
          >
            Explore
          </router-link>
        </div>
      </div>
      <div class="flex max-w-5xl">
        <div>
          <icon
            name="logo-comect-blue"
            class="fill-[#050931] w-[300px] h-[300px] lg:h-[400px] lg:w-[400px]"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup></script>

<style scoped></style>

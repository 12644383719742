<template>
  <div class="w-full mx-auto p-4 lg:p-8">
    <div
      v-if="loading >= 3 && !errorMessage"
      class="w-full 2xl:max-w-[70%] mx-auto p-4 lg:p-8 rounded-lg shadow border-gray-100 border-[0.2px] mb-16"
    >
      <div
        class="flex flex-col justify-center items-center lg:flex-row lg:items-start bg-white p-6 lg:p-10 rounded-lg mt-8"
      >
        <div
          class="lg:basis-3/4 basis-1/2 flex-col lg:flex-row flex items-center lg:items-start space-x-0 md:space-x-4"
        >
          <div
            class="flex items-center justify-center rounded-full w-24 h-24 lg:w-36 lg:h-36"
          >
            <img
              :src="
                profile?.profileImage ||
                require('/assets/images/logo_comect.png')
              "
              alt="Logo"
              class="w-20 h-20 lg:w-[110px] lg:h-[110px] rounded-full"
            />
          </div>
          <div class="flex flex-col w-full">
            <div
              class="space-y-1 flex flex-col justify-center items-center lg:justify-start lg:items-start"
            >
              <div
                class="flex items-center justify-center lg:justify-start space-x-2 font-semibold text-[#050931]"
              >
                <p v-if="profile?.name" class="text-xl lg:text-2xl">
                  {{ profile?.name }}
                </p>
                <icon
                  name="certified"
                  class="fill-black h-5 w-5 lg:h-6 lg:w-6"
                />
              </div>
              <p class="text-base lg:text-lg font-sans text-[#050931]">
                {{ profile?.sector }}
              </p>
            </div>

            <div
              v-if="invitationLink"
              class="flex flex-col lg:flex-row justify-between items-center py-5 pl-0 lg:pl-54"
            >
              <div>
                <button
                  @click="showDynamicQrCode = true"
                  class="bg-[#4030C6] flex justify-center items-center w-28 h-8 text-white font-semibold rounded-lg"
                >
                  Join
                </button>
              </div>
              <div
                v-if="
                  templateLists &&
                  templateLists['Membership'] &&
                  templateLists['Membership'][0] &&
                  memberCount
                "
                class="flex space-x-4 mt-5 lg:mt-0"
              >
                <div class="flex items-center space-x-2">
                  <img
                    :src="require('/assets/images/user.png')"
                    alt="Members"
                    class="h-[19.29px] w-[19.29px]"
                  />
                  <span class="text-black font-medium text-[14px]"
                    >{{ memberCount }} Members</span
                  >
                </div>
                <div class="flex items-center space-x-2">
                  <icon
                    name="certified_light"
                    class="h-[19.29px] w-[19.29px]"
                  />
                  <span class="text-black font-medium text-[14px]"
                    >{{
                      Object.keys(templateLists).reduce((cnt, type) => {
                        return cnt + templateLists[type].length;
                      }, 0)
                    }}
                    Badges</span
                  >
                </div>
                <div class="flex items-center space-x-2">
                  <img
                    :src="require('/assets/images/calendar_.png')"
                    alt="Date"
                    class="h-[19.29px] w-[19.29px]"
                  />
                  <span class="text-black font-medium text-[14px]">
                    {{ createdDate || 'N/A' }}
                  </span>
                </div>
              </div>
            </div>

            <div class="py-5">
              <p
                class="text-[17px] font-semibold text-start w-full text-gray-900"
              >
                Description
              </p>
              <p class="text-black text-[17px]" style="font-weight: 400">
                {{ profile?.description }}
              </p>
            </div>
          </div>
        </div>
        <div
          v-if="
            templateLists &&
            templateLists['Membership'] &&
            templateLists['Membership'][0]
          "
          class="lg:basis-1.5/4 basis-1/2 mt-5 lg:mt-0 px-4"
        >
          <img
            :src="
              templateLists['Membership'][0]?.data?.image ||
              require('/assets/images/banner.png')
            "
            alt="Banner"
            class="w-[100%] md:w-[80%] rounded-lg mx-auto"
          />
        </div>
      </div>
      <div v-if="socialMedia.length > 0" class="w-full px-0 lg:px-44">
        <p class="text-2xl my-5 font-semibold text-start w-full text-gray-900">
          Social
        </p>
        <div class="flex space-x-4 w-full mx-auto">
          <template v-for="social in socialMedia" :key="index">
            <a
              v-if="social.value"
              :href="social.value"
              target="_blank"
              class="flex items-center justify-center space-x-1 border-gray-300 border-[1px] w-24 h-7 rounded-full"
            >
              <icon
                :name="social.name"
                class="fill-black w-4 h-4"
                :class="{
                  'fill-indigo-700': social.name == 'website',
                  '!fill-[#7289da]': social.name == 'discord',
                }"
              />
              <p class="text-start text-sm text-gray-400">
                {{ social.name[0].toUpperCase() + social.name.slice(1) }}
              </p>
            </a>
          </template>
        </div>
      </div>
      <div class="w-full border-t lg:px-10 border-[#E5E7EB] my-3"></div>
      <div class="flex justify-center w-full items-center flex-col">
        <h2
          class="text-2xl my-10 lg:px-44 font-semibold text-start w-full text-gray-900"
        >
          Latest badges
        </h2>
        <div
          v-if="templates && profile"
          class="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-5 lg:grid-cols-4 2xl:grid-cols-5 gap-5"
        >
          <div
            v-for="(template, index) in templates"
            :key="index"
            class="rounded-lg"
          >
            <TemplatePreview
              :badgeImage="template.data.image"
              :badgeName="template.data.name"
              :badgeLocation="null"
              :badgeStartDate="null"
              :badgeEndDate="null"
              :issuerName="profile.name"
              :formatMd="true"
              type="basic"
              :isCommunityPage="true"
            />
          </div>
        </div>
      </div>
      <div
        v-if="invitationLink"
        class="w-full max-w-lg mx-auto p-4 lg:px-8 space-y-5 2xl:text-xl flex flex-col items-center"
      >
        <p class="text-center font-medium text-black">
          To discover and explore badges, join the community download and use
          the myDID APP
        </p>
        <button
          @click="showDynamicQrCode = true"
          class="bg-[#4030C6] flex justify-center items-center w-28 h-8 2xl:text-xl 2xl:w-36 2xl:h-10 text-white font-semibold rounded-lg"
        >
          Discover
        </button>
      </div>
    </div>
    <div
      v-else-if="loading < 3 && !errorMessage"
      class="flex justify-center items-center"
    >
      <div class="flex justify-center mb-5">
        <custom-loader class="" color="#D0C4E7" size="50px"></custom-loader>
      </div>
    </div>
    <div v-else-if="errorMessage">
      <p>{{ errorMessage }}</p>
    </div>

    <div v-if="showDynamicQrCode">
      <div
        @click="showDynamicQrCode = false"
        class="w-full h-full fixed top-0 left-0 z-40 bg-black bg-opacity-75 flex justify-center items-center"
      >
        <div
          class="overflow-y-auto overflow-x-hidden rounded-lg shadow-lg bg-white p-8"
        >
          <h2 class="text-xl font-bold mb-4">
            Scan this QR code to join the community
          </h2>
          <div class="bg-white flex justify-center mb-10">
            <qrcode-vue
              :value="invitationLink"
              :size="250"
              level="L"
              :margin="1"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute } from 'vue-router';
import { ref, inject, watch } from 'vue';
import TheFooter from '@/components/TheFooter.vue';
import TemplatePreview from '@/components/TemplatePreview.vue';
import TheHeader from '@/components/TheHeader.vue';
export default {
  components: { TheHeader, TemplatePreview, TheFooter },
  setup() {
    const route = useRoute();
    const api = inject('api');
    const utils = inject('utils');

    const profile = ref(null);
    const socialMedia = ref([]);
    const templateLists = ref(null);
    const lang = ref('en');
    const loading = ref(false);
    const errorMessage = ref(null);
    const memberCount = ref(0);
    const showDynamicQrCode = ref(false);
    const invitationLink = ref('');
    const memberShipTemplateHash = ref(null);
    const createdDate = ref(null);
    const templates = ref(null);
    if (route.query.lang) {
      lang.value = route.query.lang;
    }

    // retrieve did and address
    const did = route.params.id;

    const addr = utils.didToAddress(did);
    if (did) {
      loading.value = 0; // Début du chargement
      api.getIssuerDetailsLinkFromCode(did).then((res) => {
        const profileIpfsUrl = res.data.service[0]?.serviceEndpoint;
        utils.getJsonDataFromUrl(profileIpfsUrl).then((issuerProfile) => {
          profile.value = issuerProfile;
          const socialNetworks = ['twitter', 'discord', 'website'];
          socialMedia.value = socialNetworks
            .filter((network) => issuerProfile[network])
            .map((network) => {
              return {
                name: network,
                value: issuerProfile[network],
              };
            });
        });
      });

      Promise.all(
        ['Basic', 'Community', 'Participation', 'Membership', 'Role'].map(
          (type) => {
            return new Promise(async (resolve, reject) => {
              const templateList = await utils.getTemplateList(addr, type);
              resolve({ type, list: templateList });
            });
          }
        )
      )
        .then((res) => {
          const lists = [];
          res.forEach((el) => (lists[el.type] = el.list));
          templateLists.value = lists;
          memberShipTemplateHash.value = lists['Membership'][0]?.templateHash;

          // Get the first 5 Basic templates data from ipfs
          utils
            .getTemplateListData(lists['Basic'].slice(0, 5))
            .then((templateDataList) => {
              templates.value = templateDataList;
            });

          api
            .getCommunityMemberCount(
              memberShipTemplateHash.value.split('0x')[1]
            )
            .then((res) => {
              memberCount.value = res.data.members.number;
              loading.value = loading.value + 1;
            });

          // Get the older template hash from the membership template history
          utils
            .getOldestTemplateHash(memberShipTemplateHash.value)
            .then((hash) => {
              utils.getTemplateData(hash).then((templateData) => {
                createdDate.value = new Date(
                  templateData.created
                ).toLocaleDateString();
                loading.value = loading.value + 1;
              });
            });
        })
        .catch((error) => {
          console.error('Error fetching template lists:', error);
          errorMessage.value = 'Error fetching template lists';
          loading.value = 10;
        });

      // Get invitation link from the issuer card
      utils.getMemberCardsList().then((memberCardList) => {
        const memberCard = memberCardList.find((card) => card.issuer === addr);
        if (!memberCard) return;

        const invitationLinkCode = utils.removeLeadingZerosAndConvertToASCII(
          memberCard.invitationLink
        );

        invitationLink.value = `${process.env.VUE_APP_REDIRECT_LINK}/${invitationLinkCode}`;
        loading.value = loading.value + 1;
      });
    }

    return {
      profile,
      lang,
      loading,
      did,
      templateLists,
      memberCount,
      templates,
      createdDate,
      errorMessage,
      invitationLink,
      showDynamicQrCode,
      socialMedia,
    };
  },
};
</script>

<style scoped></style>

import { createRouter, createWebHistory } from 'vue-router';
import VueCookies from 'vue-cookies';
import jwt from 'jsonwebtoken';
import Home from './views/Home';
import Community from './views/Community.vue';
import FeaturedCommunities from './views/FeaturedCommunities.vue';
import Welcome from './views/Welcome.vue';
import AdminComminitiesList from './views/AdminComminitiesList.vue';
import AdminCommunityDetail from './views/AdminCommunityDetail.vue';
import AdminLogin from './views/AdminLogin.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', component: Home, name: 'Badge details' },
    { path: '/welcome', component: Welcome, name: 'Welcome' },
    {
      path: '/admin/communities-list',
      component: AdminComminitiesList,
      name: 'Communities List',
      meta: { requiresAuth: true },
    },
    {
      path: '/admin/community/:id',
      component: AdminCommunityDetail,
      props: true,
      name: 'Community Detail',
      meta: { requiresAuth: true },
    },
    {
      path: '/community/:id',
      component: Community,
      props: true,
      name: 'Community',
    },
    {
      path: '/communities',
      component: FeaturedCommunities,
      name: 'Communities',
    },
    {
      path: '/admin-login',
      component: AdminLogin,
      name: 'Admin Login',
    },
    { path: '/:notFound(.*)', redirect: '/' },
  ],
});

router.beforeEach((to, from, next) => {
  // verify if the route requires authentication
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const token = VueCookies.get('token');
    if (!token) {
      return router.push('/admin-login');
    }
    const decodedToken = jwt.decode(token);

    /* console.log(decodedToken);
    if (!decodedToken) {
      return router.push('/admin-login');
    }
    if (decodedToken.exp < Date.now() / 1000) {
      VueCookies.remove('token');
      return router.push('/admin-login');
    } */
  }

  next();
});

export default router;

import { defineStore } from 'pinia';

export const useCommunityStore = defineStore('community', {
  state: () => ({
    shownCommunities: [],
    hiddenCommunities: [],
    shownStart: 0,
    hiddenStart: 0,
    shownTotalItems: 0,
    hiddenTotalItems: 0,
    isHiddenCommunities: false,
    initialLoading: false,
    loadingMore: false,
  }),
  getters: {
    currentCommunities(state) {
      return state.isHiddenCommunities
        ? state.hiddenCommunities
        : state.shownCommunities;
    },
    currentStart(state) {
      return state.isHiddenCommunities ? state.hiddenStart : state.shownStart;
    },
    currentTotalItems(state) {
      return state.isHiddenCommunities
        ? state.hiddenTotalItems
        : state.shownTotalItems;
    },
  },
  actions: {
    addCommunities(newCommunities) {
      if (this.isHiddenCommunities) {
        this.hiddenCommunities = [...this.hiddenCommunities, ...newCommunities];
      } else {
        this.shownCommunities = [...this.shownCommunities, ...newCommunities];
      }
    },
    setStart(start) {
      if (this.isHiddenCommunities) {
        this.hiddenStart = start;
      } else {
        this.shownStart = start;
      }
    },
    setTotalItems(total) {
      if (this.isHiddenCommunities) {
        this.hiddenTotalItems = total;
      } else {
        this.shownTotalItems = total;
      }
    },
    setIsHiddenCommunities(isHidden) {
      this.isHiddenCommunities = isHidden;
    },
    setInitialLoading(loading) {
      this.initialLoading = loading;
    },
    setLoadingMore(loading) {
      this.loadingMore = loading;
    },
    removeCommunity(did) {
      if (this.isHiddenCommunities) {
        this.hiddenCommunities = this.hiddenCommunities.filter(
          (community) => community.did !== did
        );
      } else {
        this.shownCommunities = this.shownCommunities.filter(
          (community) => community.did !== did
        );
      }
    },
    resetCurrentList() {
      if (this.isHiddenCommunities) {
        this.hiddenCommunities = [];
        this.hiddenStart = 0;
      } else {
        this.shownCommunities = [];
        this.shownStart = 0;
      }
    },
  },
});
